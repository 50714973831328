<template>
  <a-modal
    :visible="visible"
    :title="'注销前，请确认以下内容！'"
    centered
    :destroyOnClose="true"
    @cancel="close"
  >
    <!--  注销账号form  -->
    <div class="logoutContent">
      <div class="textOnt">1、您仅可注销您本人申请的账号</div>
      <div class="textTwo">2、注销后，账号下的全部权益将被清除</div>
      <div class="textThree">
        3、注销后，账号下的所有数据、记录等将无法访问和找回
      </div>
      <div class="textOntF">4、账号一旦注销完成，将无法恢复</div>
    </div>

    <template v-slot:footer>
      <a-button @click="close">取消</a-button>

      <!--      <a-button v-if="showSafe" type="primary" @click="gotoOk">-->
      <!--        下一步-->
      <!--      </a-button>-->
      <a-button type="primary" @click="handleOkO"> 确认注销 </a-button>
    </template>
  </a-modal>
  <div  class="modalClass" ref="container">
    <a-modal
      :visible="visibleT"
      title="注销协议"
      centered
      :destroyOnClose="true"
      :getContainer="() => container"
      @cancel="close"
    >
      <!--  注销账号form  -->
      <div class="logoutContentT fs-16 fontTapy">
        <div class="textOnt textBold6 fs-22">人脉宝盒账号注销协议</div>
        <div class="textTwo textBold5">
          <div>【特别说明】亲爱的用户，在您正式开始下一步人脉宝盒账号注销流程前，我们先为您做出如下特别说明：当您按照注销提示提交注销申请时，即表示您已充分阅读、理解本注销协议的全部内容。如您不同意注销协议的相关条款和约定，请您及时停止注销程序。
            注销人脉宝盒账号后，您将无法再以此账号登录和使用人脉宝盒，人脉宝盒也将同时终止为该账号提供我们的高品质、多元化的产品与服务，这同时也不可避免地会给您的售后维权带来不便。且人脉宝盒账号一旦注销完成，将无法恢复。请您在注销前慎重考虑。 若您经过慎重考虑后仍执意决定注销人脉宝盒账号的，请您务必先行仔细阅读和充分理解本《人脉宝盒账号注销协议》，在同意全部内容后，按照我们的注销操作指引点击进行下一步操作。
          </div>
          <div>
            人脉宝盒在此善意的提醒您，如人脉宝盒账号下的信息对您来说十分重要，您应在申请注销之前将所有数据和信息(包括但不限于个人资料、待办、记录、联系人信息等)自行备份。注销成功后，我们将删除您的个人信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据法律法规的要求，您账号的相关交易记录须自交易完成之日起在人脉宝盒保存3年甚至更长的时间。
          </div>
          <div>1.如果您仍欲继续注销账号，您的账号需同时满足以下条件：</div>
          <div>（1）账号不在处罚状态中，且能正常登录；</div>
          <div>（2）账号最近一个月内并无修改密码、修改关联手机、绑定手机记录。</div>
          <div>2.您应确保您有权决定该账号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何争议，由您自行承担。</div>
          <div>3.您理解并同意，账号注销后我们无法协助您重新恢复前述服务。请您在申请注销前自行备份您欲保留的本账号信息和数据。</div>
          <div>4.账户注销的流程是：</div>
          <div>（1）用户通过应用内置的账号注销功能或者通过在线客服，提交账号注销申请；</div>
          <div>（2）人脉宝盒运营团队受理并确认用户的注销申请；</div>
          <div>（3）系统完成注销操作，并通过邮件、短信等方式将注销结果告知用户。</div>
          <div>5.账号注销后，已绑定的手机号、邮箱将会被解除绑定。</div>
          <div>6.注销人脉宝盒账户，您将无法再使用本人脉宝盒账户，也将无法找回您人脉宝盒账户中及与账户相关的任何内容或信息(即使您使用相同的手机号码或微信再次注册并使用人脉宝盒)，注销后果包括但不限于：
          </div>
          <div>(1)您将无法登录、使用人脉宝盒账户；</div>
          <div>(2)人脉宝盒账户的个人资料和相关信息(包括但不限于用户名、昵称、头像、待办数据、记录数据、联系人数据、交易完成已超过3年的交易记录等)都将无法找回；</div>
          <div>(3)您通过人脉宝盒账号进行登录的人脉宝盒所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的包括但不限于：订阅权益和当前账户所剩余的金币以及金币兑换的相关权益将视为您自行放弃，将无法继续使用，由此引起一切纠纷由您自行处理，我们不承担任何责任。
          </div>
          <div>7.在账号注销期间，如果您的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，我们有权自行终止您的账号注销程序，而无需另行得到您的同意。
          </div>
          <div>8.请注意，注销您的账号并不代表本账号注销前的账号行为和相关责任得到豁免或减轻。</div>
          <div>9.未尽事宜，参照注册等其他协议处理。</div>
          <div></div>
        </div>
        <div class="textThree"></div>
        <div class="textOntF"></div>
      </div>

      <template v-slot:footer>
        <a-button @click="close">不同意</a-button>

        <!--      <a-button v-if="showSafe" type="primary" @click="gotoOk">-->
        <!--        下一步-->
        <!--      </a-button>-->
        <a-button type="primary" @click="handleOkT"> 同意 </a-button>
      </template>
    </a-modal>
  </div>

  <!--  注销账号、安全认证    -->
  <a-modal
    :visible="visibleO"
    title="安全验证"
    centered
    :destroyOnClose="true"
    @cancel="close"
  >
    <!--  注销账号form  -->
    <a-form
      ref="safeRef"
      :model="safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <!--    name="account"   -->
      <a-form-item label="手机号">
        <!--        <a-input-->
        <!--          autocomplete="off"-->
        <!--          v-model:value="safeForm.account"-->
        <!--          :placeholder="'请输入手机号'"-->
        <!--        />-->
        <div>{{ mobile }}</div>
      </a-form-item>
      <a-form-item name="code" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input v-model:value="safeForm.code" placeholder="请输入验证码" />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button @click="closeThree">取消</a-button>

      <!--      <a-button v-if="showSafe" type="primary" @click="gotoOk">-->
      <!--        下一步-->
      <!--      </a-button>-->
      <a-button type="primary" @click="handleOk"> 确认注销 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Col, Form, message, Row } from 'ant-design-vue';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import personalApi from '@/api/personal';
import { validMobile } from '@/utils/validation';
import loginApi from '@/api/login';
import { local, session } from '@/utils/storage';
import personalApi from '@/api/personal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const formRef = ref();
    const safeRef = ref();
    // const router = useRouter();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      visiblePE: false,
      // showSafe: true,
      loading: false,
      visibleO: false,
      visibleT: false,
      safeForm: { account: '', code: '' },
      timer: null,
      showVal: null,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      activeUpload: '',
      mobile: '',
    });
    const form = reactive({
      id: userId ? userId : '',
    });

    watch(
      () => props.init,
      value => {
        console.log('value', value);
        state.mobile = value.mobile;
      }
    );
    const logout = () => {
      //  注销成功后退出登录
      loginApi.logout('').then(() => {
        local.clear();
        session.clear();
        location.reload();
        // 请求成功或者失败时关闭
        context.emit('fnOk', false);
        state.loading = false;
        // 关闭注销账号弹窗
        state.visibleO = false;
      });
    };

    const required = { required: true, whitespace: true, message: '不能为空' };
    // 基本信息校验

    onMounted(() => {});

    return {
      container,
      ...toRefs(state),
      form,
      formRef,
      safeRef,
      userId,
      safeRules: {
        account: [
          required,
          {
            validator: (rule, value) => {
              if (value) {
                if (validMobile(value)) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入合法的手机号码');
              }
              return Promise.reject();
            },
          },
        ],
        code: required,
      },

      // gotoOk() {
      //   state.showSafe = false;
      // },
      closeThree() {
        state.visibleO = false;
        context.emit('update:visible', false);
      },

      close() {
        state.visible = false;
        state.visibleT = false;
        state.visibleO = false;
        context.emit('update:visible', false);
      },

      handleGetCode() {
        // safeRef.value.validateFields('account').then(() => {
        state.btn = { disabled: true, title: '重新获取(60s)' };
        let count = 60;
        state.timer = setInterval(() => {
          if (count === 1) {
            state.btn = { disabled: false, title: '获取验证码' };
            clearInterval(state.timer);
            state.timer = null;
          } else {
            count--;
            state.btn.title = `重新获取(${count}s)`;
          }
        }, 1000);
        personalApi.sendSmsVerifyCodeByUserId('', userId).then(res => {
          if (res) {
            message.success('验证码已发送，请注意查收');
          }
        });
        // });
      },
      handleOkO() {
        // state.visible = false
        context.emit('update:visible', false);
        state.visibleT = true;
      },
      handleOkT() {
        state.visibleT = false;
        state.visibleO = true;
      },

      handleOk() {
        safeRef.value.validate().then(() => {
          if (state.loading) return;
          state.loading = true;
          let parmas = {
            mobile: state.mobile,
            userId: userId,
            verifyCode: state.safeForm.code,
            areaCode: '86',
            isSure: 1,
          };
          personalApi
            .removeUser('', parmas)
            .then(res => {
              if (res) {
                message.success('注销账号成功');
                logout();
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
    };
  },
};
</script>

<style scoped lang="less">
.modalClass /deep/ .ant-modal-body {
  height: 540px;
  overflow: auto;
  padding-bottom: 0px;
}
.modalClass /deep/ .ant-modal-footer {
  padding: 24px;
}
.logoutContent {
  color: #fa5151;
}

.logoutContentT {
  .textOnt {
    text-align: center;
  }
}
</style>
